<template>
  <b-card
    no-body
    class="cursor-pointer"
  >
    <b-card-body>
      <b-avatar
        class="mb-1"
        variant="light-secondary"
        size="45"
      >
        <span
          style="width: 21px; height: 21px;"
          v-html="icon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ title }}
        </h2>
        <div class="font-small-3 mb-1">
          {{ desc }}
        </div>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
}
</script>
