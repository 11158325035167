<template>
  <div>
    <PropertyTopBox />
    <b-row>
      <b-col
        v-for="(item, index) in fbg"
        :key="index"
        lg="4"
        sm="6"
      >
        <PropertyFBGBox
          :icon="item.icon"
          :title="item.title"
          :desc="item.desc"
          @click.native="$router.push({name: 'rooms'})"
        />
      </b-col>
    </b-row></div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { mapState } from 'vuex'
import PropertyFBGBox from './PropertyFBGBox.vue'
import PropertyTopBox from './PropertyTopBox.vue'
import { propertiesModelField, propertiesType } from '@/api/field_model'
import { basementSvg, floorsSvg, groundSvg } from '@/assets/custom_svg'

export default {
  components: {
    PropertyTopBox, PropertyFBGBox, BCol, BRow,
  },
  data() {
    return {
      fbg: [
        {
          icon: 'UsersIcon',
          title: 'Floors',
        },
        {
          icon: 'UsersIcon',
          title: 'Ground',
        },
        {
          icon: 'UsersIcon',
          title: 'Basements',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      propertyModel: state => state.currentProperty.propertyModel,
    }),
  },
  mounted() {
    const { propertyModel } = this
    this.fbg = [
      {
        title: 'Floors',
        icon: floorsSvg,
        // eslint-disable-next-line no-nested-ternary
        desc: propertyModel[propertiesModelField.type] === propertiesType.building ? (propertyModel.numberOfFloorsNo > 0 ? `${(propertyModel.numberOfFloorsNo - propertyModel.numberOfFloorsNo + 1)} ~ -${propertyModel.numberOfFloorsNo} Floors` : 'You haven\'t added floors') : 'Change Property type in setting to manage your floors',
      },
      {
        title: 'Ground',
        icon: groundSvg,
        desc: propertyModel.numberOfGroundNo > 0 ? `001~00${propertyModel.numberOfGroundNo} Units` : 'No Ground Units added',
      },
      {
        title: 'Basements',
        icon: basementSvg,
        desc: propertyModel.numberOfBasementsNo > 0 ? `-${(propertyModel.numberOfBasementsNo - propertyModel.numberOfBasementsNo + 1)} ~ -${propertyModel.numberOfBasementsNo} Basements` : 'You haven\'t added basements',
      },
    ]
  },

}
</script>

<style scoped>

</style>
