<template>
  <b-sidebar
    id="property-setting-sidebar"
    :visible="isPropertySettingSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-property-setting-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Property Setting
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <b-row class="p-2">
        <h6 class="section-label mx-1 mb-2">
          REMINDERS
        </h6>
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label="Lease Expiry Reminder Days Before"
            label-for="lease-expiry-reminder-days-before"
            description="Set to 30"
          >
            <b-form-input
              id="lease-expiry-reminder-days-before"
              v-model="propertyModel[propertiesModelField.leaseExpiryReminderDaysBefore]"
              trim
              type="number"
              placeholder="Enter number in days"
            />

          </b-form-group>
          <b-form-group
            label="Tenant Rent Reminder Days Before"
            label-for="tenant-rent-reminder-days-before"
            description="Set to 30"
          >
            <b-form-input
              id="tenant-rent-reminder-days-before"
              v-model="propertyModel[propertiesModelField.tenantRentReminderDaysBefore]"
              trim
              type="number"
              placeholder="Enter number in days"
            />

          </b-form-group>
          <b-form-group
            label="Tenant Overdue Reminder Days After"
            label-for="tenant-overdue-reminder-days-after"
            description="Set to 30"
          >
            <b-form-input
              id="tenant-overdue-reminder-days-after"
              v-model="propertyModel[propertiesModelField.tenantOverdueReminderDaysAfter]"
              trim
              type="number"
              placeholder="Enter number in days"
            />

          </b-form-group>
          <h6 class="section-label mx-1 mb-2">
            REMINDERS
          </h6>
          <b-form-group
            label="Check out time"
            label-for="check-out-time"
            description="Set to 30"
          >
            <flat-pickr
              v-model="propertyModel[propertiesModelField.checkOutTime]"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        block
        variant="primary"
        :disabled="isLoading"
        class="mt-1"
        @click="onSubmit"
      >
        UPDATE
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BSidebar, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import { mapGetters, mapState } from 'vuex'
import { propertiesModelField } from '@/api/field_model'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BSidebar, flatPickr, BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPropertySettingSidebarActive',
    event: 'update:is-property-setting-sidebar-active',
  },
  props: {
    isPropertySettingSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      propertiesModelField,
    }
  },
  computed: {
    ...mapGetters('addEditProperty', [
      'isLoading',
    ]),
    ...mapState({
      propertyModel: state => state.currentProperty.propertyModel,
    }),
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    onSubmit() {
      const payload = {
        propertyModel: this.propertyModel,
        notify: this.showToast,
        updateProperty: true,
      }
      this.$store.dispatch('addEditProperty/updateProperty', payload)
        .then(() => {
          this.$emit('update:is-property-setting-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#property-setting-sidebar {
  width: 500px;
}
</style>
