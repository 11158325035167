<template>
  <b-sidebar
    v-if="show"
    id="add-new-reminder-sidebar"
    :visible="isAddNewReminderSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-reminder-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <b-button
          v-if="isEdit"
          size="sm"
          :disabled="isLoading"
          :variant="reminderModel[remindersModelField.isCompleted] ? 'outline-success' : 'outline-secondary'"
          @click="changeReminderStatus"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>{{ reminderModel[remindersModelField.isCompleted] ? ' Completed' : ' Mark Complete' }}</span>
        </b-button>

        <h5
          v-else
          class="mb-0"
        >
          {{ 'Add New Reminder' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Date Due -->
          <b-form-group
            label="Due date"
            label-for="due-date"
          >
            <flat-pickr
              v-model="reminderModel[remindersModelField.dateAndTime]"
              class="form-control"
            />
          </b-form-group>

          <!-- Category -->
          <b-form-group
            label="Category"
            label-for="Category"
          >

            <v-select
              v-model="category"
              label="title"
              label-for="Category"
              placeholder="Select Category"
              :options="reminderCategories"
            />
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              v-model="reminderModel[remindersModelField.notes]"
              placeholder="Notes"
              rows="3"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isLoading"
              class="mr-2"
              type="submit"
            >
              {{ isEdit ? 'Update': 'Add' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>

          </div>
          <b-button
            v-if="isEdit"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            block
            class="mt-1"
            :disabled="isLoading"
            variant="danger"
            @click="onDelete"
          >
            Delete
          </b-button>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapState } from 'vuex'
import { nextTick, ref } from '@vue/composition-api'
import { ReminderCategory, remindersModelField } from '@/api/field_model'
import { defaultReminderModel } from '@/api/defaultModel'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { LogicStateStatus } from '@/libs/ajax'

const reminderCategory = new ReminderCategory()
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BSpinner,

    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReminderSidebarActive',
    event: 'update:is-add-new-reminder-sidebar-active',
  },
  props: {
    isAddNewReminderSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      remindersModelField,
      reminderCategories: reminderCategory.reminderCategories(),
      category: null,
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.addEditReminder.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  methods: {
    onDelete() {
      const payload = {
        reminderModel: this.reminderModel,
        notify: this.showToast,
      }
      this.$store.dispatch('addEditReminder/deleteReminder', payload)
        .then(() => {
          this.$emit('update:is-add-new-reminder-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    changeReminderStatus() {
      const reminderModel = { ...this.reminderModel }
      reminderModel[remindersModelField.isCompleted] = !reminderModel[remindersModelField.isCompleted]
      this.onSubmit(reminderModel)
    },
    onSubmit(v) {
      const reminderModel = v ? { ...v } : { ...this.reminderModel }
      reminderModel[remindersModelField.category] = this.category ? this.category.value : null
      reminderModel.propId = this.$route.query.propId
      const payload = {
        reminderModel,
        notify: this.showToast,
      }
      this.$store.dispatch(`addEditReminder/${this.isEdit ? 'updateReminder' : 'addReminder'}`, payload)
        .then(() => {
          this.reset()
          this.$emit('update:is-add-new-reminder-sidebar-active', false)
        })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const reminderModel = { ...defaultReminderModel }
    const show = ref(true)

    const reset = () => {
      show.value = false
      nextTick(() => {
        show.value = true
      })
    }

    const resetreminderModel = () => {
      reminderModel.value = JSON.parse(JSON.stringify(defaultReminderModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreminderModel)

    return {
      reminderModel,
      show,
      reset,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-reminder-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
