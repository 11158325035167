<template>
  <div>
    <AddEditReminder
      ref="addEditReminderRef"
      :is-add-new-reminder-sidebar-active.sync="isAddNewReminderSidebarActive"
      :is-edit="isEdit"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            v-if="!isViewOnly"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                variant="primary"
                @click="addReminder"
              >
                <span class="text-nowrap">Add New Reminder</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        id="reminder-overlay-background"
        :show="isLoading"
        variant="blur"
        :opacity="0.5"
        blur="2px"
        rounded="sm"
      >
        <b-card
          class="m-1"
          no-body
        >
          <b-card-body class="p-0">

            <section class="todo-application-2">

              <div class="content-todooo">

                <div style="height: inherit">

                  <div class="todo-app-list">
                    <div class="todo-task-list-wrapper list-group scroll-area">

                      <ul class="todo-task-list media-list">
                        <ReminderCard
                          v-for="(reminder, index) in reminders"
                          :key="keyChange + '_' + index"
                          :value="reminder"
                          @onClick="onClick"
                        />
                      </ul>
                    </div>
                  </div>

                </div>

              </div>

            </section>
          </b-card-body>
        </b-card>
      </b-overlay>

      <div class="mx-2 mb-2">

        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BButton, BRow, BPagination, BOverlay, BCardBody,

} from 'bootstrap-vue'
import { mapState } from 'vuex'
import AddEditReminder from './components/AddEditReminder.vue'
import ReminderCard from './components/ReminderCard.vue'

import { LogicStateStatus } from '@/libs/ajax'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { defaultReminderModel } from '@/api/defaultModel'
import { ReminderCategory, remindersModelField } from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    AddEditReminder,
    BOverlay,
    BCard,
    ReminderCard,
    BCardBody,
    BButton,
  },
  mixins: [AuthMixin],
  data() {
    return {
      currentPage: 1,
      keyChange: 0,
      isAddNewReminderSidebarActive: false,
      propId: this.$route.query.propId,
      perPage: 13,
      isEdit: false,
    }
  },
  computed: {
    from() {
      return this.perPage * (this.currentPage - 1) + (this.reminders.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.reminders.length
    },
    of() {
      return this.total
    },
    ...mapState({
      reminders: state => state.reminders.reminders,
      total: state => state.reminders.total,
      isLoading: state => state.reminders.logicStateStatus === LogicStateStatus.loading,
    }),
  },
  watch: {
    currentPage() {
      this.getReminders()
    },
  },
  mounted() {
    this.$store.commit('reminders/UPDATE_REMINDERS', [])
    this.getReminders()
  },
  methods: {
    addReminder() {
      this.isEdit = false
      this.$refs.addEditReminderRef.reminderModel = { ...defaultReminderModel }
      this.$refs.addEditReminderRef.category = null
      this.isAddNewReminderSidebarActive = true
    },
    onClick(value) {
      this.isEdit = true
      this.$refs.addEditReminderRef.reminderModel = { ...value }
      if (value[remindersModelField.category] && value[remindersModelField.category].length > 0) {
        this.$refs.addEditReminderRef.category = {
          value: value[remindersModelField.category],
          title: new ReminderCategory().getLabel(value[remindersModelField.category]),
        }
      }
      this.isAddNewReminderSidebarActive = true
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    getReminders() {
      const payload = {
        page: this.currentPage,
        propId: this.propId,
        notify: this.showToast,
      }
      this.$store.dispatch('reminders/getReminders', payload)
        .then(() => { this.keyChange += 1 })
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo-2.scss";
</style>
