<template>
  <section
    class="invoice-add-wrapper"
  >
    <b-row class="invoice-add">
      <!-- Left Col: Card -->
      <PropertyLeftSide />

      <!-- Col: Right (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding">
              <!-- <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo mr-1">
                    Add New Sale
                  </h3>

                </div> -->
              <b-tabs
                content-class="pt-1"
                fill
                @input="onInput"
              >
                <b-tab
                  active
                >
                  <div slot="title">
                    <b-avatar
                      rounded
                      size="40"
                      variant="transparent"
                      class="mb-1"
                      v-html="floorsSvg"
                    />
                    <div>Home</div>
                  </div>
                  <PropertyHomeTab />
                </b-tab>
                <b-tab>
                  <div slot="title">
                    <b-avatar
                      rounded
                      size="40"
                      variant="transparent"
                      class="mb-1"
                      v-html="expenseSvg"
                    />
                    <div>Expenses</div>
                  </div>
                  <ExpensesPage
                    v-if="visitedTabs.includes(1)"
                    :prop-id="Number($route.query.propId)"
                  />
                </b-tab>
                <b-tab>
                  <div slot="title">
                    <b-avatar
                      rounded
                      size="40"
                      variant="transparent"
                      class="mb-1"
                      v-html="remindersSvg"
                    />
                    <div>Reminders</div>
                  </div>
                  <RemindersPage v-if="visitedTabs.includes(2)" />
                </b-tab>
                <b-tab>
                  <div slot="title">
                    <b-avatar
                      rounded
                      size="40"
                      variant="transparent"
                      class="mb-1"
                      v-html="tenantsSvg"
                    />
                    <div>Tenants</div>
                  </div>
                  <TenantsPage
                    v-if="visitedTabs.includes(3)"
                    :prop-id="Number($route.query.propId)"
                  />
                </b-tab>
              </b-tabs>

            </b-card-body>

          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol, BRow, BCard, BCardBody, BForm, BTab, BTabs, BAvatar,
} from 'bootstrap-vue'
import RemindersPage from '../Reminders/RemindersPage.vue'
import TenantsPage from '../Tenants/TenantsPage.vue'
import ExpensesPage from '../Expenses/ExpensesPage.vue'
import PropertyHomeTab from './components/PropertyHomeTab.vue'
import PropertyLeftSide from './components/PropertyLeftSide.vue'
import {
  floorsSvg, expenseSvg, remindersSvg, tenantsSvg,
} from '@/assets/custom_svg'

export default {
  components: {
    BCol, BRow, BCard, BCardBody, BForm, PropertyLeftSide, BTab, BTabs, PropertyHomeTab, BAvatar, ExpensesPage, TenantsPage, RemindersPage,
  },
  data() {
    return {
      floorsSvg,
      expenseSvg,
      remindersSvg,
      tenantsSvg,
      visitedTabs: [],
    }
  },
  methods: {
    onInput(v) {
      if (!this.visitedTabs.includes(v)) {
        this.visitedTabs.push(v)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
