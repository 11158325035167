<template>
  <b-col
    cols="12"
    md="4"
    xl="3"
    class="invoice-actions mt-md-0 mt-2"
  >
    <b-card>

      <div class="d-flex justify-content-start">
        <b-avatar
          :src="image"
          size="85px"
          rounded
        />
        <div class="d-flex flex-column ml-1">
          <div class="mb-1">
            <h4 class="mb-0">
              {{ propertyModel.nm }}
            </h4>
            <span class="card-text">{{ desc }}</span>
          </div>
        </div>
      </div>
      <hr>
      <!-- Button: Send Invoice -->
      <b-button
        v-if="isEditPropertyAllowed"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="light"
        block
        @click="onEditProperty"
      >
        Edit Property
      </b-button>
      <b-button
        v-if="isEditPropertyAllowed"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="light"
        block
        @click="isPropertySettingSidebarActive = true"
      >
        Property Setting
      </b-button>

    </b-card>
    <PropertySettingPage :is-property-setting-sidebar-active.sync="isPropertySettingSidebarActive" />
    <add-edit-property
      ref="addEditPropertyRef"
      :is-add-new-property-sidebar-active.sync="isAddNewPropertySidebarActive"
      :is-edit="true"
    />
  </b-col>

</template>

<script>
import {
  BCard, BCol, BButton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import AddEditProperty from '../../AddEditProperty/AddEditProperty.vue'
import PropertySettingPage from '../../PropertySetting/PropertySettingPage.vue'
import { propertiesModelField, PropertiesType, propertiesType } from '@/api/field_model'
import { noImage } from '@/assets/helpers'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BCard, BCol, BButton, BAvatar, PropertySettingPage, AddEditProperty,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  data() {
    return {
      isPropertySettingSidebarActive: false,
      isAddNewPropertySidebarActive: false,
    }
  },
  computed: {
    ...mapState({
      propertyModel: state => state.currentProperty.propertyModel,
    }),
    image() {
      return this.propertyModel[propertiesModelField.mainImage] && this.propertyModel[propertiesModelField.mainImage].length > 0 ? this.propertyModel[propertiesModelField.mainImage] : noImage
    },
    desc() {
      const { propertyModel } = this
      return propertyModel[propertiesModelField.type] === propertiesType.building ? `${propertyModel.numberOfFloorsNo} Floors, ${propertyModel.numberOfBasementsNo} Basement(s)`
        : `${propertyModel.numberOfGroundNo} Units, ${propertyModel.numberOfBasementsNo} Basement(s)`
    },
  },
  methods: {
    onEditProperty() {
      this.$refs.addEditPropertyRef.propertyModel = { ...this.propertyModel }
      const pM = this.$refs.addEditPropertyRef.propertyModel
      if (pM[propertiesModelField.type] && pM[propertiesModelField.type].length > 0) {
        this.$refs.addEditPropertyRef.propertyModel[propertiesModelField.type] = {
          value: pM[propertiesModelField.type],
          title: new PropertiesType().getLabel(pM[propertiesModelField.type]),
        }
      }
      this.isAddNewPropertySidebarActive = true
    },
  },
}
</script>

<style scoped>

</style>
