<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="4"
          class="d-flex align-items-between align-items-start flex-column py-1 cursor-pointer"
          @click="$router.push({name: 'filter_room', query: {isOverdue: true}})"
        >

          <h3 class="font-weight-bolder mb-0 text-success">
            {{ propertyModel.numberOfOverdue }}
          </h3>
          <b-card-text class="text-muted mb-0">
            Rent Overdue
          </b-card-text>
        </b-col>

        <b-col
          cols="4"
          class="border-left d-flex align-items-between align-items-start flex-column py-1 cursor-pointer"
          @click="$router.push({name: 'filter_room', query: {isLeaseEnd: true}})"
        >

          <h3 class="font-weight-bolder mb-0 text-danger">
            {{ propertyModel.numberOfLeaseExpire }}
          </h3>
          <b-card-text class="text-muted mb-0">
            Lease Expiring
          </b-card-text>
        </b-col>
        <b-col
          cols="4"
          class="border-left d-flex align-items-between align-items-start flex-column py-1 cursor-pointer"
          @click="$router.push({name: 'filter_room', query: {isUnrented: true}})"
        >

          <h3 class="font-weight-bolder mb-0 text-secondary">
            {{ propertyModel.numberOfUnrented }}
          </h3>
          <b-card-text class="text-muted mb-0">
            Unrented
          </b-card-text>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import {
  BCard, BCardBody, BCardText, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BCardText, BCol, BRow,
  },
  computed: {
    ...mapState({
      propertyModel: state => state.currentProperty.propertyModel,
    }),
  },
  methods: {
    showRentOverdue() {},
  },
}
</script>

<style scoped>

</style>
