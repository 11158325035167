<template>

  <li
    class="todo-item"
    :class="{ 'completed': value[remindersModelField.isCompleted] }"
    @click="onClick"
  >
    <div class="todo-title-wrapper">
      <div class="todo-title-area">
        <div class="title-wrapper">
          <!-- <b-form-checkbox
            :checked="value[remindersModelField.isCompleted]"
            @click.native.stop
            @change="changeReminderStatus"
          />
          <span class="todo-title">{{ category || '~' }}</span>
          <small>{{ value[remindersModelField.notes] }}</small>
           -->
          <b-media
            vertical-align="center"
            class="p-0"
          >
            <template #aside>
              <!-- <b-form-checkbox
                :checked="value[remindersModelField.isCompleted]"
                :disabled="loading"
                @click.native.stop
                @change="changeReminderStatus"
              /> -->
            </template>
            <h6
              class="todo-title"
            >
              {{ category || '~' }}
            </h6>
            <p class="text-muted ">
              {{ value[remindersModelField.notes] }}
            </p>

          </b-media>
        </div>

      </div>

      <div class="todo-item-action">
        <div class="badge-wrapper mr-1" />
        <small class="text-nowrap text-muted mr-1">{{ formatDate(value[remindersModelField.dateAndTime], { month: 'short', day: 'numeric'}) }}</small>
      </div>
    </div>

  </li>
</template>

<script>
import { formatDate, avatarText } from '@core/utils/filter'
import { BMedia } from 'bootstrap-vue'
import { ReminderCategory, remindersModelField } from '@/api/field_model'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

const reminderCategory = new ReminderCategory()

export default {
  components: {
    // BFormCheckbox,
    BMedia,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      // Filters
      formatDate,
      avatarText,
      loading: false,
      remindersModelField,
    }
  },
  computed: {
    category() {
      return reminderCategory.getLabel(this.value[remindersModelField.category])
    },
  },
  methods: {
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    changeReminderStatus() {
      const reminderModel = { ...this.value }
      reminderModel[remindersModelField.isCompleted] = !reminderModel[remindersModelField.isCompleted]
      const payload = {
        reminderModel,
        notify: this.showToast,
      }
      this.loading = true
      this.$store.dispatch('addEditReminder/updateReminder', payload)
        .then(() => {
          this.loading = false
          this.$forceUpdate()
        })
        .catch(error => {
          this.loading = false
          this.showToast('Error', error.message)
        })
    },
    onClick() {
      this.$emit('onClick', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>
